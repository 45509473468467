import { useState, type FC, type HTMLAttributes, type ReactNode } from "react";
import { Text } from "@cruk/cruk-react-components";

import { camelCaseToCapitalisedCase } from "@fwa/src/utils/formatUtils";
import { validate } from "@fwa/src/utils/formUtils";

import { EditableText } from "@fwa/src/components/EditableText";
import { Editable } from "@fwa/src/components/Editable";

import { type PageType, type ValidationType } from "@fwa/src/types";

type Props = {
  fieldName: string;
  text: string;
  textComponent?: FC<HTMLAttributes<HTMLElement>>;
  validation: ValidationType;
  handleEditData: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType | undefined>;
  canEdit: boolean;
  editButtonColor?: string;
  placeHolder?: ReactNode;
  fullWidth?: boolean;
};

const DefaultTextComponent = (props: HTMLAttributes<HTMLElement>) => (
  <Text as="span" {...props} />
);

export const EditableTextForm = ({
  fieldName,
  text,
  textComponent,
  placeHolder,
  validation,
  handleEditData,
  canEdit,
  editButtonColor,
  fullWidth = true,
}: Props) => {
  const [textState, setTextState] = useState<string>(text || "");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const TextComponent = textComponent ?? DefaultTextComponent;

  const handleSubmit = () =>
    handleEditData({ [`${fieldName}`]: textState.trim() }, false);

  const revalidate = () =>
    validate(textState, validation, setValidationMessage);

  return canEdit ? (
    <Editable
      fullWidth={fullWidth}
      fieldName={fieldName}
      isValid={!validationMessage.length}
      revalidate={revalidate}
      editNode={
        <EditableText
          text={text}
          textComponent={TextComponent}
          setCurrentValue={(value) => {
            setTextState(value);
          }}
          validation={validation}
          validationMessage={validationMessage}
          setValidationMessage={setValidationMessage}
          ariaLabel={fieldName ? `${fieldName} textbox` : undefined}
        />
      }
      viewNode={
        !text?.length && placeHolder ? (
          // I want to return JSX type not react node
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{placeHolder}</>
        ) : (
          <TextComponent role={undefined}>{text}</TextComponent>
        )
      }
      handleSubmit={handleSubmit}
      editButtonColor={editButtonColor}
      tooltip={`Edit ${camelCaseToCapitalisedCase(fieldName)}`}
    />
  ) : text?.length ? (
    <TextComponent role={undefined}>{text}</TextComponent>
  ) : null;
};

export default EditableTextForm;
