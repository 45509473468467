import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const EditableTextArea = styled.span<{ theme: ThemeType }>`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  padding: ${({ theme }) => theme.spacing.xxs};
  display: block;
  max-height: none;
  overflow-y: initial;
  white-space: pre-wrap;
`;

export const EditableTextBorder = styled.div<{
  $isValid: boolean;
  theme: ThemeType;
}>`
  border: solid 1px
    ${({ theme, $isValid }) =>
      $isValid ? theme.colors.inputBorder : theme.colors.danger};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};

  > * {
    min-height: 2em;
  }
`;
