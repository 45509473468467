import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const OverlayInlineEdit = styled.div`
  background: #333;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  transition:
    opacity 0.3s,
    bottom 0s 0.3s;
  z-index: 100;
`;

export const HightLight = styled.div<{
  $fullWidth: boolean;
  theme: ThemeType;
}>`
  z-index: 110;
  position: relative;
  background-color: white;
  margin: ${({ theme, $fullWidth }) =>
    $fullWidth
      ? `-${theme.spacing.s} -${theme.spacing.s} 0 -${theme.spacing.s}`
      : 0};
`;
