import { type HTMLAttributes } from "react";
import dynamic from "next/dynamic";

import { Heading } from "@cruk/cruk-react-components";

import { type PageType } from "@fwa/src/types";
import { RegexNoOnlyEmoji } from "@fwa/src/utils/formUtils";

const EditableTextForm = dynamic(
  () => import("@fwa/src/components/EditableTextForm"),
);

type Props = {
  page: PageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
};

const TitleEditableTextComponent = (
  props: HTMLAttributes<HTMLHeadingElement>,
) => (
  <Heading
    h1
    as="p"
    margin="none"
    paddingRight="s"
    {...props}
    style={{
      width: "100%",
      display: "block",
    }}
    wordBreak="break-word"
  />
);

const TitleNotEditableTextComponent = (
  props: HTMLAttributes<HTMLHeadingElement>,
) => (
  <Heading
    h1
    margin="none"
    paddingRight="s"
    {...props}
    style={{
      width: "100%",
      display: "block",
    }}
    wordBreak="break-word"
  />
);

export const PageTitle = ({ page, canEdit, updateAndMutatePage }: Props) => {
  const textComponent = canEdit
    ? TitleEditableTextComponent
    : TitleNotEditableTextComponent;

  return (
    // can i do this with textField
    <EditableTextForm
      fieldName="title"
      text={page?.title}
      textComponent={textComponent}
      handleEditData={updateAndMutatePage}
      canEdit={canEdit}
      fullWidth
      validation={{
        type: "textField",
        required: true,
        minLength: 3,
        maxLength: 100,
        regex: RegexNoOnlyEmoji,
      }}
    />
  );
};

export default PageTitle;
