import React, {
  type FC,
  useState,
  useEffect,
  type ClipboardEvent,
  type KeyboardEvent,
  type FocusEvent,
  type ChangeEvent,
  type HTMLAttributes,
  memo,
  type Dispatch,
  type SetStateAction,
} from "react";
import { ErrorText, Text, Box } from "@cruk/cruk-react-components";

import {
  EditableTextArea,
  EditableTextBorder,
} from "@fwa/src/components/EditableText/styles";

import { isBrowser } from "@fwa/src/utils/browserUtils";
import {
  validate,
  calcLength,
  sanitiseWhiteSpace,
} from "@fwa/src/utils/formUtils";
import { type ValidationType } from "@fwa/src/types";

type Props = {
  setCurrentValue: (value: string) => void;
  text: string;
  validation: ValidationType;
  setValidationMessage: Dispatch<SetStateAction<string>>;
  validationMessage?: string;
  textComponent?: FC<HTMLAttributes<HTMLElement>>;
  ariaLabel?: string;
};

// This component is for in-line editing it is based on an content editable span
// This component can assume the same styling of any title or multiline span passed in as the "textComponent"
// So that the edit in place field looks identical to the element that it is editing
// The dangerouslySetInnerHTML is to keep new lines consistent across browsers with multiline content
export const EditableText = ({
  setCurrentValue,
  text,
  validation,
  setValidationMessage,
  validationMessage = "",
  ariaLabel,
  textComponent = EditableTextArea as FC<HTMLAttributes<HTMLElement>>,
}: Props) => {
  const [textState, setTextState] = useState(text || "");
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (!isBrowser) {
      return;
    }
    const eventTarget = e.target as HTMLElement;
    const length = eventTarget.innerText
      ? calcLength(eventTarget.innerText)
      : 0;
    if (
      e.key === "Enter" &&
      (validation.type === "textField" || validation.type === "number")
    ) {
      e.preventDefault();
    }
    if (
      validation.maxLength &&
      !e.metaKey &&
      e.key !== "Tab" &&
      ![
        "Backspace",
        "Delete",
        "Escape",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
      ].includes(e.key) &&
      length >= validation.maxLength &&
      window.getSelection()?.type !== "Range"
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLElement>) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    const currentInnerText = sanitiseWhiteSpace(target.innerText);
    const currentLength = calcLength(currentInnerText);
    const maxLength = validation?.maxLength || 200;

    if (currentLength >= maxLength) return;

    const clipboardText = e.clipboardData?.getData("Text").length
      ? sanitiseWhiteSpace(e.clipboardData?.getData("Text"))
      : sanitiseWhiteSpace(e.clipboardData.getData("text/plain"));

    const totalLength = currentLength + calcLength(clipboardText);
    const isTooLong = maxLength && totalLength > maxLength;

    const clipboardTextTruncated = isTooLong
      ? clipboardText.slice(0, maxLength - currentLength)
      : clipboardText;

    const range = document.getSelection()?.getRangeAt(0);
    range?.deleteContents();
    const textNode = document.createTextNode(clipboardTextTruncated);
    range?.insertNode(textNode);
    range?.selectNodeContents(textNode);
    range?.collapse(false);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    if (range) selection?.addRange(range);
  };

  // on focus move carret to the end
  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (!isBrowser) {
      return null;
    }
    let range = null;
    let selection = null;
    range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(target); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); // get the selection object (allows you to change selection)
    if (selection && range) {
      selection.removeAllRanges(); // remove any selections already made
      selection.addRange(range);
    } // make the range you have just created the visible selection
    return undefined;
  };

  const handleInput = (e: ChangeEvent<HTMLElement>) => {
    const input = e.target as HTMLElement;
    setTextState(input.innerText);
    setValidationMessage("");
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    const input = e.target as HTMLElement;
    setTextState(input.innerText);
    setValidationMessage("");
  };

  const handleBlur = () => {
    const currentValue = sanitiseWhiteSpace(textState || "");
    validate(currentValue, validation, setValidationMessage);
    setCurrentValue(currentValue);
  };

  // reset validation message on first render
  useEffect(() => {
    setValidationMessage("");
  }, [setValidationMessage]);

  // why do I have to do this
  useEffect(() => {
    setTextState(text);
  }, [text]);

  const TextComponent = textComponent;

  return (
    <>
      <EditableTextBorder $isValid={!validationMessage.length}>
        <TextComponent
          aria-label={ariaLabel}
          className={`inline-edit__text ${validation?.type || ""}`}
          contentEditable="true"
          role="textbox"
          tabIndex={0}
          onPaste={handlePaste}
          onInput={handleInput}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={(e: KeyboardEvent<HTMLElement>) => handleKeyDown(e)}
          suppressContentEditableWarning
          dangerouslySetInnerHTML={{
            __html: text
              ?.replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/(?:\r\n|\r|\n)/g, "<br/>"),
          }}
        />
      </EditableTextBorder>
      {validation.maxLength && validation.maxLength > 0 && (
        <Box marginBottom="s">
          <Text textAlign="right">{`${
            validation.maxLength - calcLength(textState)
          } characters remaining`}</Text>
        </Box>
      )}
      {validationMessage.length ? (
        <ErrorText>{validationMessage}</ErrorText>
      ) : null}
    </>
  );
};

export default memo(EditableText);
